.btn {
    &--outline-blue {
        background-color: transparent;
        border: 1px solid;
        color: theme-color( 'primary' );
        text-shadow: none;
    }
    &--outline-blue .btn-cnt {
        color: theme-color( 'primary' );
    }
    &--outline-blue:hover .btn-cnt a, &--outline-blue:hover .fa.fa-chevron-right::before {
        color: white;
    }
    &--outline-blue + &--outline-blue {
        @media ( max-width: 572px ) {
            margin-top: 1.4rem;
        }
    }
}
