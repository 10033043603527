.hyp.hyp-view, .hyp.hyp-remove.hyp.hyp-remove, .list-step .hyp-edit.hyp-edit.hyp-edit{
    background-color: transparent;
    border: 1px solid;
    color: theme-color( 'primary' );
    text-shadow: none;
    padding: .4rem;
    border-radius: 5px;
}

.hyp.hyp-view:hover,.hyp.hyp-view:focus,.hyp.hyp-view:active, .hyp.hyp-remove.hyp.hyp-remove:hover, .hyp.hyp-remove.hyp.hyp-remove:focus,  .hyp.hyp-remove.hyp.hyp-remove:active, .list-step .hyp-edit.hyp-edit.hyp-edit:hover, .list-step .hyp-edit.hyp-edit.hyp-edit:active, .list-step .hyp-edit.hyp-edit.hyp-edit:focus {
    background-color:theme-color( 'primary' );
    color: white;
}

.action-links {
    padding-top: 0.3rem;
}

.checkout-step-title {
    font-size: rem(20px);
}

.cell-total-value, .cell-price-value {
    text-align: left;
}

