$grid-gutter-width: 24px;

/**
 * Bootstrap v3 Grid Sizing MIXED with Bootstrap v4 Flexbox Grid
 */
.row {
    box-sizing: border-box;

    display: flex;
    flex-wrap: wrap;

    // margin-right: $grid-gutter-width / -2;
    // margin-left: $grid-gutter-width / -2;
    margin-top: $grid-gutter-width * 1.25;
}

@for $i from 1 through 12 {
    .col-sm-#{$i},
    .col-md-#{$i},
    .col-lg-#{$i} {
        box-sizing: border-box;
        width: 100%;

        padding-right: $grid-gutter-width / 2;
        padding-left: $grid-gutter-width / 2;
        padding-top: $grid-gutter-width * 1.33!important;
        padding-bottom: $grid-gutter-width * 1.33!important;

        position: relative;
        height: auto !important;
    }

    //= handle sm
    @media ( min-width: 768px ) {
        .col-sm-#{$i} {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: percentage( $i / 12 );

            max-width: percentage( $i / 12 );
        }
    }

    //= handle md
    @media ( min-width: 992px ) {
        .col-md-#{$i} {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: percentage( $i / 12 );

            max-width: percentage( $i / 12 );
        }
    }

    //= handle lg
    @media ( min-width: 1200px ) {
        .col-lg-#{$i} {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: percentage( $i / 12 );

            max-width: percentage( $i / 12 );
        }
    }
}
