.SpareParts-page .columns-general {
    box-sizing: border-box;
    display: flex;
    margin: 0 -2%;
    // padding: 12px 0;
    height: 100%;
    width: 104%;
    flex-wrap: wrap;
}

.SpareParts-page .columns-general > .column {
    box-sizing: border-box;
    margin: 1rem;
    padding: 0;
    outline: none;
    vertical-align: top;
    background-color: white;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    min-height: 100%;
    flex: 1 1 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    height: auto;
    max-width: 33.333%;
}

.SpareParts-page .columns-general > .column img {
    max-width: 225px;
}

.SpareParts-page .flexi-row .column .html-wrapper {
    font-size: 0.95rem;
    background-color: #fafafa;
    align-self: flex-end;
    width: 100%;
    text-align: center;
    padding: 25px 0;
}

.SpareParts-page .column .html-wrapper h3 {
    font-size: 1.25em;
}

.SpareParts-page .flexi-row .products-flow {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0;
}

.SpareParts-page .flexi-row .center .product-set-flow-title {
    padding-top: 0;
}

.SpareParts-page .flexi-row .products-flow, .flexi-row .html-wrapper {
    padding-top: 2rem;
    padding-bottom: 0;
}

.SpareParts-page .products-flow .product-tile {
    padding-top: 0;
}

.SpareParts-page .flexi-row:first-child {
    margin-top: 2rem;
}

.SpareParts-page .flexi-row:nth-child(4) {
    margin-top: 2rem;
}
