#calculateYourPool {
    & .row{
        flex-wrap: nowrap;
    }
}

.rightContent {
    padding-top: 0 !important;
    margin-left: 1rem;
}

.tabsContainer {
    padding-top: 0 !important;
}

.ng-pristine.ng-untouched.ng-valid.ng-not-empty {
    margin-bottom: 0 !important;
}
