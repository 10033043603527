header.sticky-mode .over-summary .hyp:before {
  display: none;
  background-color: red;
}

.top-action a:hover .over-summary-number, .top-action .over-summary-number, .over-summary-number {
  background-color: #00425e;
  width: 20px;
  height: 20px;
  top: 32px !important;
}

.language.top-dropdown {
  color: white;
  & .opener {
    display: flex;
  }
  & .summary .hyp .iso, &.opener .selected-language {
    text-transform: uppercase;
    margin-right: 0.8rem;
  }
  & .opener .selected-language   {
      display: flex;
      align-items: center;
      margin-left: rem(18px);
      text-transform: uppercase;
      font-size: rem(12px);
  }
}

.opener:after {
  background-image: none;
  display: none;
}

.language.top-dropdown .opener .selected-language::after {
  @extend %font-awesome;
  margin-left: rem( 10px );
  width: rem( 18px );
  height: rem( 18px );

  content: '\f0dd'; //= sort-down
  color: gray('600');
  font-family: "FontAwesome";
  font-size: rem( 16px );
  line-height: .8;
  font-weight: 900;
}
// .colored-header-desktop {
//   background-image: url(/content/files/pool%20images/banner.jpg)!important;
// }
.breadcrumbs a, .breadcrumbs li::before {
  color: white !important;
}
