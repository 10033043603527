/**
 * Base
 */
.d-flex {
  display: flex !important; }

.align-items-center {
  align-items: center !important; }

.justify-content-center {
  justify-content: center !important; }

.flex-column {
  flex-direction: column !important; }

.language.top-dropdown .opener .selected-language::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased; }

.p--none {
  padding: 0; }

.pt--none {
  padding-top: 0; }

.pb--none {
  padding-bottom: 0; }

.pv--none {
  padding-top: 0;
  padding-bottom: 0; }

.p--xsmall {
  padding: 1rem; }

.pt--xsmall {
  padding-top: 1rem; }

.pb--xsmall {
  padding-bottom: 1rem; }

.pv--xsmall {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.p--small {
  padding: 1.5rem; }

.pt--small {
  padding-top: 1.5rem; }

.pb--small {
  padding-bottom: 1.5rem; }

.pv--small {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.p--medium {
  padding: 2rem; }

.pt--medium {
  padding-top: 2rem; }

.pb--medium {
  padding-bottom: 2rem; }

.pv--medium {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.p--large {
  padding: 3rem; }

.pt--large {
  padding-top: 3rem; }

.pb--large {
  padding-bottom: 3rem; }

.pv--large {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.m--none {
  margin: 0; }

.mt--none {
  margin-top: 0; }

.mb--none {
  margin-bottom: 0; }

.mv--none {
  margin-top: 0;
  margin-bottom: 0; }

.m--xsmall {
  margin: 1rem; }

.mt--xsmall {
  margin-top: 1rem; }

.mb--xsmall {
  margin-bottom: 1rem; }

.mv--xsmall {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.m--small {
  margin: 1.5rem; }

.mt--small {
  margin-top: 1.5rem; }

.mb--small {
  margin-bottom: 1.5rem; }

.mv--small {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.m--medium {
  margin: 2rem; }

.mt--medium {
  margin-top: 2rem; }

.mb--medium {
  margin-bottom: 2rem; }

.mv--medium {
  margin-top: 2rem;
  margin-bottom: 2rem; }

/**
 * Components
 */
.homepage-three-blocks div {
  height: auto; }

.html-wrapper > .homepage-four-blocks.row {
  margin-top: 0; }

.btn--outline-blue {
  background-color: transparent;
  border: 1px solid;
  color: #00425e;
  text-shadow: none; }

.btn--outline-blue .btn-cnt {
  color: #00425e; }

.btn--outline-blue:hover .btn-cnt a, .btn--outline-blue:hover .fa.fa-chevron-right::before {
  color: white; }

@media (max-width: 572px) {
  .btn--outline-blue + .btn--outline-blue {
    margin-top: 1.4rem; } }

.logout .arrow-blue, .logout .arrow-blue:before {
  color: white; }

strong {
  color: #00425e !important; }

/**
 * Layout
 */
/**
 * Bootstrap v3 Grid Sizing MIXED with Bootstrap v4 Flexbox Grid
 */
.row {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px; }

.col-sm-1,
.col-md-1,
.col-lg-1 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 992px) {
  .col-md-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1200px) {
  .col-lg-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

.col-sm-2,
.col-md-2,
.col-lg-2 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 992px) {
  .col-md-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 1200px) {
  .col-lg-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

.col-sm-3,
.col-md-3,
.col-lg-3 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 992px) {
  .col-md-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 1200px) {
  .col-lg-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%; } }

.col-sm-4,
.col-md-4,
.col-lg-4 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .col-md-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 1200px) {
  .col-lg-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

.col-sm-5,
.col-md-5,
.col-lg-5 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 992px) {
  .col-md-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 1200px) {
  .col-lg-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

.col-sm-6,
.col-md-6,
.col-lg-6 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 992px) {
  .col-md-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 1200px) {
  .col-lg-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%; } }

.col-sm-7,
.col-md-7,
.col-lg-7 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 992px) {
  .col-md-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 1200px) {
  .col-lg-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

.col-sm-8,
.col-md-8,
.col-lg-8 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 992px) {
  .col-md-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 1200px) {
  .col-lg-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

.col-sm-9,
.col-md-9,
.col-lg-9 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 992px) {
  .col-md-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 1200px) {
  .col-lg-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%; } }

.col-sm-10,
.col-md-10,
.col-lg-10 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 992px) {
  .col-md-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 1200px) {
  .col-lg-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

.col-sm-11,
.col-md-11,
.col-lg-11 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 992px) {
  .col-md-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

.col-sm-12,
.col-md-12,
.col-lg-12 {
  box-sizing: border-box;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 31.92px !important;
  padding-bottom: 31.92px !important;
  position: relative;
  height: auto !important; }

@media (min-width: 768px) {
  .col-sm-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .col-md-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-lg-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%; } }

.above-content.colored-header-desktop.custom-bg {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%); }

.column.footer-address .logo {
  padding-bottom: 0;
  height: auto !important; }

.middle-header__logo.middle-header__logo {
  max-width: 221px;
  height: auto;
  width: auto;
  max-height: 50px; }

header.sticky-mode .over-summary .hyp:before {
  display: none;
  background-color: red; }

.top-action a:hover .over-summary-number, .top-action .over-summary-number, .over-summary-number {
  background-color: #00425e;
  width: 20px;
  height: 20px;
  top: 32px !important; }

.language.top-dropdown {
  color: white; }
  .language.top-dropdown .opener {
    display: flex; }
  .language.top-dropdown .summary .hyp .iso, .language.top-dropdown.opener .selected-language {
    text-transform: uppercase;
    margin-right: 0.8rem; }
  .language.top-dropdown .opener .selected-language {
    display: flex;
    align-items: center;
    margin-left: 1.125rem;
    text-transform: uppercase;
    font-size: 0.75rem; }

.opener:after {
  background-image: none;
  display: none; }

.language.top-dropdown .opener .selected-language::after {
  margin-left: 0.625rem;
  width: 1.125rem;
  height: 1.125rem;
  content: '\f0dd';
  color: #757575;
  font-family: "FontAwesome";
  font-size: 1rem;
  line-height: .8;
  font-weight: 900; }

.breadcrumbs a, .breadcrumbs li::before {
  color: white !important; }

/**
 * Pages
 */
.hyp.hyp-view, .hyp.hyp-remove.hyp.hyp-remove, .list-step .hyp-edit.hyp-edit.hyp-edit {
  background-color: transparent;
  border: 1px solid;
  color: #00425e;
  text-shadow: none;
  padding: .4rem;
  border-radius: 5px; }

.hyp.hyp-view:hover, .hyp.hyp-view:focus, .hyp.hyp-view:active, .hyp.hyp-remove.hyp.hyp-remove:hover, .hyp.hyp-remove.hyp.hyp-remove:focus, .hyp.hyp-remove.hyp.hyp-remove:active, .list-step .hyp-edit.hyp-edit.hyp-edit:hover, .list-step .hyp-edit.hyp-edit.hyp-edit:active, .list-step .hyp-edit.hyp-edit.hyp-edit:focus {
  background-color: #00425e;
  color: white; }

.action-links {
  padding-top: 0.3rem; }

.checkout-step-title {
  font-size: 1.25rem; }

.cell-total-value, .cell-price-value {
  text-align: left; }

.category_block_content:hover .category_title {
  background-color: #00425e !important; }

#calculateYourPool .row {
  flex-wrap: nowrap; }

.rightContent {
  padding-top: 0 !important;
  margin-left: 1rem; }

.tabsContainer {
  padding-top: 0 !important; }

.ng-pristine.ng-untouched.ng-valid.ng-not-empty {
  margin-bottom: 0 !important; }

.SpareParts-page .columns-general {
  box-sizing: border-box;
  display: flex;
  margin: 0 -2%;
  height: 100%;
  width: 104%;
  flex-wrap: wrap; }

.SpareParts-page .columns-general > .column {
  box-sizing: border-box;
  margin: 1rem;
  padding: 0;
  outline: none;
  vertical-align: top;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  min-height: 100%;
  flex: 1 1 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
  max-width: 33.333%; }

.SpareParts-page .columns-general > .column img {
  max-width: 225px; }

.SpareParts-page .flexi-row .column .html-wrapper {
  font-size: 0.95rem;
  background-color: #fafafa;
  align-self: flex-end;
  width: 100%;
  text-align: center;
  padding: 25px 0; }

.SpareParts-page .column .html-wrapper h3 {
  font-size: 1.25em; }

.SpareParts-page .flexi-row .products-flow {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0; }

.SpareParts-page .flexi-row .center .product-set-flow-title {
  padding-top: 0; }

.SpareParts-page .flexi-row .products-flow, .flexi-row .html-wrapper {
  padding-top: 2rem;
  padding-bottom: 0; }

.SpareParts-page .products-flow .product-tile {
  padding-top: 0; }

.SpareParts-page .flexi-row:first-child {
  margin-top: 2rem; }

.SpareParts-page .flexi-row:nth-child(4) {
  margin-top: 2rem; }
